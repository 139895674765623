._404_svg {
  width: 100%;
  max-width: 800px;
}

._404_page_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem auto 8rem;
}

._not_found_title {
  font-size: 3rem;
  font-weight: 700;
  color: #d4145a;
  margin: 2rem 0;
}

._la_logo {
 width: 12.8rem;
 margin: 1rem auto 5rem;
}

/* ---------------------------------------------------------------------------------------------------------------- */
/* Contact Us Page */

._contact_help {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 4rem auto;
  gap: 1.2rem;
  min-height: 50vh;
  width: 60%;
  max-width: 1200px;
  text-align: center;
}

._contact_title {
  font-size: 2rem;
  font-weight: 700;
  color:  #272b31;
}

._contact_p {
  font-size: 1rem;
  font-weight: 500;
  color: rgb(87, 87, 87);
  line-height: 1.5;
  letter-spacing: .42px;
  margin: .5rem auto;
  width: 90%;
}

._contact_list li {
  list-style: none;
  margin: .5rem 0;
  color: rgb(87, 87, 87);
}

._contact_list li a {
  margin: .6rem 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: .5rem;
  color: rgb(87, 87, 87);
  text-decoration: none;
  cursor: pointer;
}

._contact_list li a:hover {
  color: #ff724f;
}

/* ----------------------------------------------------------------------------------------------------------- */
/* Policy Page */

/* ._terms_of_use_container */
._policy_container {
  /* text-align: center; */
  min-height: 42vh;
  max-width: 1280px;
  margin: 0rem auto;
}


._Policy_text_container {
  width: 80%;
  margin: 3rem auto;
  display: flex;
  gap: 3rem;
  flex-direction: column;
}

._policy_text_div {
  display: flex;
  gap: 2rem;
  flex-direction: column;
}

._policy_first_section {
  display: flex;
  flex-direction: column;
  gap: .8rem;
}

/* ._terms_title,
._policy_title {
  color: #272b31;
} */

._p_num {
  font-size: 1.2rem;
  color: #272b31;
}

._policy_text_div ul {
  margin: .6rem 0;
}

._policy_text_div ul li {
  font-size: 1rem;
  color: rgb(87, 87, 87);
  list-style: none;
  margin-top: .42rem;
}

._p_l {
  color: #182C61;
}

._spans_div {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

/* ._policy_container p,
._terms_of_use_container p {
  margin: 1rem;
  color:  rgb(87, 87, 87);
  font-size: 1.2rem;
} */

._payment_success_container {
  max-width: 1280px;
  margin: 2rem auto;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

._payment_success_logo {
  width: 12rem;
}

._payment_success_text {
  text-align: center;
  width: 70%;
  margin: 5rem auto 8rem;
}

._payment_success_text h3 {
  font-size: 2rem;
  font-weight: 600;
  color: #ff724f;
}

._payment_success_text p  {
  font-size: 1.2rem;
  line-height: 1.3;
  letter-spacing: .5px;
  color: #272b31;
  margin: 1.3rem auto;
}

 ._policy_container span {
    margin-left: 1.5rem;
  }

@media screen and (max-width: 1024px) {
  /* ._policy_container,
  ._terms_of_use_container {
    gap: 1rem;
    width: 90%;
  } */

  ._Policy_text_container {
    width: 90%;
  }

  /* ._policy_title,
  ._terms_title {
    font-size: 1.8rem;
    margin: 0;
  } */

  ._la_logo {
    width: 8.5rem;
  }

   ._payment_success_logo {
      width: 8.5rem;
    }

  @media screen and (max-width: 800px) {
    /* ._account_settings_container {
      width: 80%;
    } */

    ._Policy_text_container {
      width: 95%;
    }

    ._payment_success_text {
      width: 95%;
    }

    ._payment_success_text p  {
      font-size: 1rem;
    }

    @media screen and (max-width: 600px) {
      ._Policy_text_container {
        width: 100%;
      }

      /* ._help_container {
        margin: 5rem auto 0;
        width: 95%;
      } */

      ._help_container h3 {
        font-size: 1.1rem;
        font-weight: 600;
      }

      ._help_container p {
        font-size: .8rem;
      }

      /* ._policy_container,
      ._terms_of_use_container {
        gap: 1rem;
        width: 95%;
        margin: 1.8rem auto;
        gap: .3rem;
      } */

      /* ._policy_title,
      ._terms_title {
        font-size: 1.6rem;
        margin: 0;
      } */

      /* ._policy_container span {
        font-weight: 600;
        margin-left: 1rem;
      } */

      /* ._policy_container div>h4,
      ._terms_of_use_container div>h4 {
        font-size: 1.3rem;
        font-weight: 500;
        margin: .5rem 0;
      } */

      /* ._policy_polit_points,
      ._terms_polit_points {
        margin: 1.2rem 0 1.2rem 1.6rem;
      } */

      /* ._account_settings_container {
        width: 98%;
        margin: 2rem auto;
        gap: 0.6rem;
      } */

      /* ._account_setting_title {
        font-size: 1.6rem;
      }

      ._setting_items {
        padding: .6rem;
      }

      ._setting_items>p {
        font-size: .8rem;
      } */

      /* ._coming_soon,
      ._auto_tweet {
        font-size: .7rem;
      } */

      /* ._terms_text_container div>p {
        margin: .6rem 0;
      } */

       ._la_logo {
        width: 5.8rem;
      }

      ._payment_success_logo {
        width: 5.8rem;
      }

      ._payment_success_text h3 {
        font-size: 1.3rem;
      }

      ._p_num {
        font-size: 1rem;
      }
    }
  }
}