.Footer {
  margin: 1.8rem auto 2rem;
  border-top: 1px solid #182C61;
  max-width: 1280px;
}

.footerLogo {
  width: 7.42rem;
  padding-top: .5rem;
}

.footerLinks {
  display: flex;
  gap: 3rem;
  padding: .5rem;
}

.footerLinks {
  margin-bottom: .5rem;
}

.footerLinks p {
  font-size: 1.05rem;
  font-weight: 400;
  cursor: pointer;
  color: #21212f;
  font-family: "Inter Tight", sans-serif;
}

._links a {
  font-size: 1.6rem;
}

._links {
  gap: .8rem;
}

.footerMain {
  margin: .5rem auto 0;
}

.footerMain,
._links {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.copy {
  display: flex;
  gap: .5rem;
  align-items: center;
  justify-content: center;
  color: #ff724f;
}

@media screen and (max-width: 1042px) {
  @media screen and (max-width: 800px) {
    .footerLinks {
      gap: 1.5rem;
    }
    @media screen and (max-width: 600px) {
      .Footer {
        margin: 3.8rem 0 2rem;
        border-top: 1px solid #182C61;
      }

      .footerMain {
        flex-direction: column;
        margin: 1rem auto 0;
      }

      .footerLogo {
        width: 5.3rem;
      }

      .footerLinks {
        margin: 0rem auto 1.2rem;
        gap: 1.2rem;
      }

      .footerLinks div p {
        font-size: .9rem;
      }
    }
  }
}