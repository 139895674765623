.pricing {
  margin: 5rem auto;
  background-color: #ffffff;
  border-radius: 1rem;
  padding: 2rem 1.2rem;
  background-image: url('../../Assets/Lines.svg');
  background-position: center;
}

._pricing_title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

._switcher {
  position: relative;
  width: 118px;
  height: 50px;
  display: inline-block;
  margin: 2rem 0;
}

._slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #efeef0;
  transition: .4s;
  border-radius: 34px;
}

._switcher input {
  display: none;
}

._slider::before {
  position: absolute;
  content: "";
  width: 42px;
  height: 42px;
  left: 4px;
  bottom: 4px;
  background-color: #fff;
  transition: .4s;
  border-radius: 50px;
}

._switcher input:checked + ._slider {
  background-color: #fcad05;
}

._switcher input:checked + ._slider::before {
  transform: translateX(68px);
}

._pricing_title h1 {
  color: #8e48f4;
  font-size: 4.2rem;
  margin: .42rem;
  font-family: "Inter Tight", sans-serif;
}

._pricing_title p {
  font-size: 1.5rem;
  width: 65%;
  text-align: center;
  line-height: 1.3;
}

#original_price {
  font-size: 1.5rem;
  text-decoration: line-through;
}

.dis_price {
  color: #fcad05;
  font-size: 1.2rem;
  font-style: italic;
  margin-left: .3rem;
}

.pricing_item1 h4,
.pricing_item0 h4,
.pricing_item2 h4 {
  margin: .5rem 0;
  font-size: 2rem;
}

._p_sub_title h4 span {
  font-size: 1.6rem;
  font-family: "Yanone Kaffeesatz", sans-serif;
  letter-spacing: .3px;
  color: #fcad05;
}

.pricing_item2 h4 {
  margin: .5rem 0 3rem;
}

._prices_container {
  display: flex;
  gap: 2.5rem;
  align-items: center;
  width: 100%;
  align-items: stretch; 
  justify-content: center;
  margin: 2.6rem 0 2rem;
}

.pricing_item0,
.pricing_item1,
.pricing_item2 {
  border-radius: .8rem;
  padding: 1rem;
  display: flex;
  gap: 1.2rem;
  flex-direction: column;
  justify-content: space-between;
  max-width: 20rem;
  min-height: 29rem;
  flex-grow: 1;
}

.pricing_item1 {
  background-color: #272b31;
  color: #ffff;
}

.pricing_item2 {
  background-color: #efeef0;
}

.pricing_item1 button,
.pricing_item2 button,
.pricing_item0 button {
  color: #272b31;
  background-color: #cdf449;
  border: transparent;
  border-radius: .42rem;
  padding: .3rem 0;
  font-size: 1.6rem;
  height: 3rem;
  font-weight: 500;
  cursor: pointer;
  font-family: "Inter Tight", sans-serif;
  width: 100%;
}

.pricing_item2 button {
color: #ffff;
  background-color: #272b31;
}

._pricing_list li {
  font-size: 1.3rem;
  list-style: none;
  margin: .6rem 0;
  display: flex;
  gap: .6rem;
  align-items: center;
  justify-content: flex-start;
}

._price {
  font-size: 1.6rem;
  font-family: "Inter Tight", sans-serif;
}

._price span {
  font-size: 1.3rem;
  letter-spacing: .2px;
  color: #844edf;
}

.pricing_item2 p {
  font-size: 1.16rem;
  font-weight: 400;
  color: rgb(90, 91, 91);
  font-family: "Inter Tight", sans-serif;
}

._free_link {
  color: #efeef0;
}

._pricing_list li p {
  font-weight: 500;
  font-size: 1.1rem;
  font-family: "Inter Tight", sans-serif;
}

._pricing_list p span,
._p_sub_title p span {
  font-style: italic;
  font-size: .8rem;
  color: #747475;
}

.pricing_btns_div {
  background-color: #fff;
  padding: .5rem .8rem;
  border-radius: .8rem;
  margin: 1rem 0;
  display: flex;
  justify-content: space-between;
  gap: .6rem;
  width: 25%;
}

.pice_btns {
  font-size: 1.2rem;
  padding: .4rem .6rem;
  width: 100%;
  border-radius: .3rem;
  border: transparent;
  background-color: #e4e4e4;
  cursor: pointer;
  color: #272b31;
}

.pice_btns_active {
  background-color: #fcad05;
}

._monthly_yearly_price {
  font-size: 1rem;
  color: #fcad05;
}

.pricing_item0 {
  background-color: #272b31;
  color: #ffffff;
}

.pricing_item0 button a,
.pricing_item1 button a {
  color: #272b31;
}

@media screen and (max-width: 1042px) {
  ._pricing_title h1 {
    font-size: 3.2rem;
  }

  .pricing_item1 h4, .pricing_item0 h4, .pricing_item2 h4 {
    font-size: 1.5rem;
  }

  ._price {
    font-size: 1.3rem;
  }

  @media screen and (max-width: 800px) {
    .pricing_btns_div {
      border-radius: .5rem;
      width: 50%;
    }

    ._pricing_title {
      width: 90%;
      line-height: 1.5;
      margin: auto;
    }

    ._pricing_title h1 {
      font-size: 3rem;
      margin: .2rem;
    }

    ._pricing_title p {
      width: 98%;
      font-size: 1.3rem;
    }

    ._switcher {
      width: 110px;
      height: 48px;
      margin: 1.3rem 0 2rem;
    }

    ._slider::before {
      left: 5px;
      bottom: 3px;
    }

    ._switcher input:checked + ._slider {
      background-color: #fcad05;
      left: -8px;
    }

    ._prices_container {
      flex-direction: column;
      align-items: center;
    }

    @media screen and (max-width: 600px) {
      .pricing {
        margin: 1rem auto;
        padding: 2rem .3rem;
      }

      .pricing_btns_div {
        width: 90%;
      }

      ._pricing_title {
        width: 99%;
        text-align: center;
        line-height: 1.3;
      }

      ._pricing_title p {
        font-size: 1.1rem;
        width: 99%;
      }

      ._pricing_title h1 {
        font-size: 2rem;
      }

      ._price span  {
        font-size: 1rem;
      }

      .pricing_item1 button,
      .pricing_item2 button,
      .pricing_item0 button {
        font-size: 1.3rem;
      }
    }
  }
}