._logo {
 width: 12.8rem;
}

.Header {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 2.5rem auto .5rem auto;
  max-width: 1280px;
  position: relative;
  z-index: 1000;
}

._nav {
  display: flex;
  align-items: center;
  gap: 1.3rem;
  position: -webkit-sticky;
  margin: .3rem auto;
  background-color: #ffffff;
  padding: 0.8rem 1rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 0.8rem;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

._nav_scrolled {
  position: fixed;
  top: 1rem;
  z-index: 1000;
}

._nav li a {
  color: #606060;
}

._nav li {
  font-size: 1.3rem;
  font-weight: 500;
  font-family: 'Archivo', sans-serif;
  cursor: pointer;
  list-style: none;
}
._header_btn {
  font-family: 'Archivo', sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
  padding: 0.3rem 0.7rem;
  border-radius: 0.6rem;
  background: #ff724f;
  border: transparent;
  color: #21212f;
  font-style: normal;
  cursor: pointer;
}

@media screen and (max-width: 1042px) {
  ._logo {
    width: 8.5rem;
  }

  ._nav li {
    font-size: 1.2rem;
  }

  @media screen and (max-width: 800px) {
    ._nav li {
      font-size: 1rem;
    }

    @media screen and (max-width: 600px) {
      ._logo {
        width: 5.8rem;
      }

      ._nav_scrolled {
        top: .3rem;
      }

      .Header {
        margin-top: 1.5rem;
        position: relative;
      }
    }
  }
}