._features_container {
  margin: 10rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column-reverse;
  text-align: center;
  gap: 3rem;
}

._features_imag_container {
  width: 85%;
}

._use_chat_folderz_img {
  width: 100%;
  border-radius: 1rem;
}

._features_copy {
  width: 80%;
}

._features_title h2 {
  font-size: 2.3rem;
  color: #ff724f;
  text-align: center;
}

/* ._features_title p {
  color: #606060;
  font-size: 1.2rem;
  margin: .6rem 0;
} */

._features_points {
  display: flex;
  flex-direction: column;
  margin: 1rem auto 2.3rem;
  gap: .6rem;
  width: 100%;
}

._features_text {
  width: 99%;
  margin: 1rem auto 3rem;
  font-size: 1.3rem;
  line-height: 1.3;
  letter-spacing: .2px;
  color: #606060;
}

._features_points li {
  font-size: 1.2rem;
  color: #21212f;
  line-height: 1.3;
  letter-spacing: .4px;
  font-weight: 500;
  list-style: none;
  color: #606060;
}

._features_btn {
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: .6px;
  border: transparent;
  background-color: #ff724f;
  color: #21212f;
  padding: .5rem 1rem;
  border-radius: .6rem;
  font-family: "Inter Tight", sans-serif;
  cursor: pointer;
}

._features_title {
  width: 85%;
  margin: 0rem auto;
}

._features_coming_soon {
  font-size: .8rem;
  font-style: italic;
  color: #606060;
  font-family: "Yanone Kaffeesatz", sans-serif;
}


@media screen and (max-width: 1042px) {
  ._features_title h2 {
    font-size: 1.8rem;
  }

  ._features_points {
    margin: 1rem auto 1.5rem;
  }

  ._features_points li {
    font-size: 1rem;
    line-height: 1.2;
    letter-spacing: .3px;
  }

  ._features_title p {
    margin: .3rem 0;
  }

  ._features_btn {
    font-size: 1.3rem;
  }

  @media screen and (max-width: 800px) {
    ._features_container {
      margin: 8rem 2rem;
      align-items: center;
      justify-content: center;
      flex-direction: column-reverse;
      text-align: center;
    }

    ._features_imag_container {
      width: 85%;
    }

    ._use_chat_folderz_img {
      width: 100%;
      /* height: 65vh; */
      object-fit: cover;
    }

    ._features_copy {
      width: 90%;
    }

    ._features_points  {
      width: 100%;
      margin: .8rem auto 1.8rem;
    }

    ._features_points li {
      text-align: center;
    }

    ._features_title {
      margin: 0rem auto;
      width: 100%;
    }

    @media screen and (max-width: 600px) {

      ._features_container {
        margin: 4rem 0rem;
      }

      ._features_copy {
        width: 98%;
      }

      ._features_imag_container {
        width: 99%;
      }

      /* ._use_chat_folderz_img {
        height: 45vh;
      } */
      
      ._features_title h2 {
        font-size: 1.3rem;
      }

      /* ._features_title {
        margin-bottom: 1.5rem;
      } */
    }
  }
}