.hero {
  margin: 3rem auto;
  text-align: center;
}

.hero h2 {
  font-size: 4rem;
  width: 70%;
  margin: auto;
  font-weight: 700;
  color: #21212f;
  font-family: "Inter Tight", sans-serif;
}

._first_span, ._second_span {
  display: inline-block;
  position: relative;
}

._hero_line2, ._hero_line {
  position: absolute;
  left: 0;
  z-index: -1;
}

._first_span,
._second_span {
  position: relative;
}

._hero_line {
  bottom: -.8rem;
  right: -.5rem;
}

._hero_line2 {
  left: .5rem;
  bottom: -.7rem;
} 

.hero p {
  width: 70%;
  font-size: 1.6rem;
  margin: 2rem auto;
  font-family: 'Archivo', sans-serif;
  line-height: 1.3;
  color: #606060;
}

.hero p span {
  font-style: italic;
  color: black;
  font-family: 'Source Serif 4', serif;
  font-size: 1.42rem;
  font-weight: 600;
}

.emailDiv {
  max-width: 65%;
  margin: 0rem auto 7.3rem;
  display: flex;
  justify-content: center;
  gap: 1.2rem;
}

.emailDiv button {
  border-radius: .8rem;
  border: transparent;
  background-color: #ff724f;
  font-size: 1.3rem;
  padding: .8rem 1.2rem;
  font-family: "Inter Tight", sans-serif;
  font-weight: 700;
  cursor: pointer;
  color: #21212f;
  font-style: normal;
}

.promImg {
  width: 95%;
  margin: 1.6rem auto 0;
  border-radius: 1.2rem;
  border: .5px solid rgb(226, 226, 224);
}

._i_img_container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

._best_i {
  font-size: 1.3rem;
  letter-spacing: .6px;
  font-weight: 600;
  color: #21212f;
}

._i_img {
  width: 10rem;
}

._claude_container {
  position: relative;
}

#_claude_coming_soon {
  position: absolute;
  right: -1rem;
  font-size: 1rem;
  color: #8e48f5;
  top: 0;
  width: 100%;
  font-family: "Yanone Kaffeesatz", sans-serif;
  font-style: italic;
}

 .videoWrapper {
    position: relative;
    width: 90%; 
    padding-top: 38.85%; 
    margin: 0 auto;
    overflow: hidden;
    border-radius: 1.42rem;
  }

 .videoEl {
    /* position: absolute; */
    /* top: 0; */
    /* left: 0; */
    width: 100%;
    height: 100%;
    border-radius: 1.42rem;
    border: transparent;
  }

@media screen and (max-width: 1042px) {
  .hero h2 {
    width: 80%;
  }

  .hero h2 {
    font-size: 3rem;
  }

  .hero p {
    width: 85%;
    font-size: 1.5rem;
    margin: 1.8rem auto;
  }

  @media screen and (max-width: 800px) {
    .hero {
      margin: 2rem auto;
    }

    .hero h2 {
      width: 85%;
    }

    .emailDiv {
      max-width: 80%;
    }

    .hero h2 {
      font-size: 2.3rem;
    }
    
    ._hero_line {
      bottom: -.5rem;
    }

    ._hero_line2 {
      bottom: -.36rem;
    } 

    .hero p {
      font-size: 1.2rem;
      margin: 1.3rem auto;
    }

    .emailDiv button {
      font-size: 1.2rem;
      padding: .8rem 1rem;
      font-weight: 700;
      letter-spacing: .5px;
    }

    ._best_i {
      font-size: 1.2rem;
    }

    ._i_img {
      width: 6.8rem;
    }

    #_claude_coming_soon {
      font-size: .75rem;
    }


    @media screen and (max-width: 600px) {
      .hero {
        margin: 1.6rem 0 1.5rem;
      }

      .hero h2 {
        font-size: 1.8rem;
        width: 98%;
      }

      ._hero_line2 {
        bottom: -.26rem;
      } 

      .emailDiv {
        max-width: 85%;
        flex-direction: column;
      }

      .emailDiv input {
        width: 100%;
      }

      .hero p span {
        font-size: 1rem;
      }

      .promImg {
        width: 96%;
        margin: 1rem auto 0;
        border-radius: .42rem;
      }

      .hero p {
        margin: 1rem auto 1.8rem;
        width: 95%;
        font-size: 1rem;
      }

      ._i_img_container {
        gap: 1rem;
      }

      ._i_img {
        width: 5rem;
      }

      ._best_i {
        font-size: .8rem;
      }

      .emailDiv {
        margin: 0rem auto 3.6rem;
      } 

       #_claude_coming_soon {
        font-size: .5rem;
      }

    }
  }
}