@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0 .6rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #efeef0;
  font-family: "Inter Tight", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.creator_public_page,
.Home {
  max-width: 1280px;
  margin: auto;
  overflow: hidden;
}

a {
  color: black;
  text-decoration: none;
}

.TheLoader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fbfffe91;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.spinnerImg {
  width: 8rem;
}

@media screen and (max-width: 1024px) {
  .App {
    margin: auto 1rem;
  }

  @media screen and (max-width: 800px) {
    @media screen and (max-width: 600px) {
      .App {
        margin: auto;
        width: 98%;
        overflow: hidden;
      }
    }
  }
}